export const headers = {
  "Content-Type": "application/fhir+json",
  Accept: "application/json",
  //Authorization: "Basic Y29udGVpcjpxN25TeHRGdA==",
}
export const snowstormHeaders = {
  "Accept-Language": "no-X-47351000202107,no-X-61000202103,no",
}

//FHIR server
export const fhirServer = "https://fhir.conteir.no/fhir"
//export const fhirServer = "http://localhost:8080/fhir"
export const r5CdsFhirServer = {
  baseUrl: "https://r5.conteir.no/fhir/ClinicalUseDefinition",
  medicineSctIdParam: "subject.product-classification",
  populationCharacteristicRangeParam: "population.characteristic-range",
  populationCharacteristicRangeParamGfrSctId: "80274001",
  populationCharacteristicRangeParamAgeSctId: "424144002",
  includeParam: "_include",
  includeParamValue: "ClinicalUseDefinition:*",
}

export const r5FhirServerCdsServices = "https://r5.conteir.no/cds-services/legemiddelgjennomgang"

//Nyre
export const r5FhirServerCdsServicesNyre = "https://r5.conteir.no/cds-services/nedsatt-nyre"


//Felleskatalogen indikasjonssøk
export const indikasjonUrl = "https://demo.felleskatalogen.no/ir/medisin/sok?type=indikasjon&felt=sctid&sokord=";
//Lim inn dette i file  du vil bruke denne urlen: import { indikasjonUrl } from "../config.js"

//Felleskatalogen bivirkningssøk
export const bivirkningUrl = "https://demo.felleskatalogen.no/ir/medisin/sok?type=bivirkning&felt=varenr,sctid,festid&sokord=";
//Lim inn dette i file  du vil bruke denne urlen: import { bivirkningUrl } from "../config.js"

//Felleskatalogen interaksjonssøk
export const interaksjonUrl = "https://www.felleskatalogen.no/ir/medisin/sok?type=interaksjon&felt=varenr,sctid,festid&sokord=";
//Lim inn dette i file  du vil bruke denne urlen: import { interaksjonUrl } from "../config.js"


// export const fhirServer = "http://localhost:8080/fhir";

//cqf-ruller Framework upon HAPI FHIR
// export const baseCDSServiceUrl = "http://165.227.134.100:8080";
// export const baseCDSServiceUrl = "https://cqf-server.conteir.no";
//! localhost with latest extension support
// export const baseCDSServiceUrl = "http://localhost:8080";
//! deployed with latest extension support
// export const baseCDSServiceUrl = "http://142.93.173.248:8080";
//! deployed with latest extension support deployed:
export const baseCDSServiceUrl = "https://cqf-extension-server.conteir.no"

//Snomwstorm server core parameters
const terminlogyServer =
// "https://rainbowcolors.conteir.no/snowstorm/snomed-ct" //referansesett: 88791000202108 mangler medlemmer her.
  "https://snowstorm.terminologi.ehelse.no/snowstorm/snomed-ct"
const mainBranch = "MAIN/SNOMEDCT-NO"
const queryParameters =
  "active=true&offset=0&limit=10&groupByConcept=true"

//Snowstorm endpints
const endpointConcepts = "/" + mainBranch + "/concepts?"
const endpointTerms = "/browser/" + mainBranch + "/descriptions?"

//Query filters
const bodyStructure = "&ecl=<91723000"
const observation = "&ecl=<363787002"
const findingAndDisorder = "&conceptRefset=88161000202101"
const clinicalDrug = "&conceptRefset=88791000202108" // "&ecl=<763158003" midlertidig erstatning for Refset=88791000202108 ved bruk av rainbowcolors server.

//Queries
export const conceptQueryBodyStructure =
  terminlogyServer +
  endpointConcepts +
  queryParameters +
  bodyStructure +
  "&term="

export const conceptQueryfindingAndDisorder =
  terminlogyServer +
  endpointTerms +
  queryParameters +
  findingAndDisorder +
  "&term="

export const conceptQueryClincialDrug =
  terminlogyServer + endpointTerms + queryParameters + clinicalDrug + "&term="

export const conceptQueryObservation =
  terminlogyServer + endpointConcepts + queryParameters + observation + "&term="

export const pulseobservationURL =
  "https://hannastest.hannakhonskaya.repl.co/cds-services/pulseobservation"

export const conteirApiURL = (code) =>
  `https://apicdshooks.herokuapp.com/api/conteir/?codeSystem=snomed&code=${code}`

export const conteirApiAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTU3NTkwMTJ9.E0K-W9VkP88Y4bJDbVfAJ7RRSF-qEFfhdSVY-KzZrow"

export const smartAppUrl = (code) =>
  // `http://localhost:3002?code=${code}`;
  // `https://smart-on-fhir-app.netlify.app?code=${code}`;
  `https://dev-smart-on-fhir-app.netlify.app?code=${code}`

export const authServer = "https://dev-smartauth.herokuapp.com"

export const GETparameters = {
  method: "GET",
  credentials: "include",
  mode: "cors", // ?
  headers: headers,
}
