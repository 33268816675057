import React, { useState, useRef } from "react"
import "./styles.css"
import "./Cds.css"
import "./AutoComplete.css"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
  FloatingLabel,
  Form,
} from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import Autosuggest from "react-autosuggest"
import "./AutoComplete.css"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import HomeIcon from "@mui/icons-material/Home"
import Loader from "./Loader"
//import Modal from "./Modal"
import { ClinicalUseDefinitionComponent } from "./ClinicalUseDefinitionComponent"
import { CdsResponseComponent } from "./CdsRisikolegemiddel"
import { CdsKidneyComponent } from "./CdsKidney"
import {
  fhirServer,
  conceptQueryClincialDrug,
  conceptQueryfindingAndDisorder,
  headers,
  snowstormHeaders,
 // r5CdsFhirServer,
  r5FhirServerCdsServices,
  r5FhirServerCdsServicesNyre,
} from "../config.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ErrorModal from './ErrorModal';
import kidneySymbolImage from './assets/nyre.png';


const courseTypes = [
  {
    code: "acute",
    system:
      "http://terminology.hl7.org/CodeSystem/medicationrequest-course-of-therapy",
    display: "Kur",
  },
  {
    code: "continuous",
    system:
      "http://terminology.hl7.org/CodeSystem/medicationrequest-course-of-therapy",
    display: "Fast bruk",
  },
]
function MedicationRequest(Props) {
  // const [showMoreCard, setShowMoreCard] = useState(undefined)

  const [formDetails, setFormDetails] = useState(undefined)
  const [showJson, setShowJson] = useState(false)
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [selectedMedication, setSelectedMediccation] = useState(null)
  const params = useParams()
  const location = useLocation()
  const [ConditionValue, setConditionValue] = useState("")
  const [ConditionSuggestions, setConditionSuggestions] = useState([])
  const navigator = useNavigate()
  const [selectedCondition, setSelectedCondition] = useState(null)
  const [loader, setLoader] = useState(false)
  const [dataCards /*setCards*/] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  // eslint-disable-next-line
  const [state, setState] = useState(false)
  // const forceUpdate = () => setState((prevState) => !prevState) // Denne kan du bruke for å tvinge en oppdatering av komponenten
  const [isIFrameOpen, setIsIFrameOpen] = useState(true)


  
  const [cdsResponseData, setCdsResponseData] = useState(null)
  const [kidneyResponseData, setKidneyResponseData] = useState(null);
  const [showWarningCard, setShowWarningCard] = useState(false);
  const [showKidneyCard, setShowKidneyCard] = useState(false);
  const [shouldShowKidneySymbol, setShouldShowKidneySymbol] = useState(false);
  const [shouldShowWarningTriangle, setShouldShowWarningTriangle] = useState(false);

  const [clinicalUseDefinitionResources, setClinicalUseDefinitionResources] =
    useState(null)


  const closeCard = () => {
    setClinicalUseDefinitionResources([])
  }

  // Definer tilstand for å lagre alder
  const [patientAge, setPatientAge] = useState(null)

  // I din overordnede komponent
  // const [showCard, setShowCard] = useState(false);



  const closeCard1 = () => {
    setShowWarningCard(false);
  };

  const handleTriangleClick = () => {
    setShowWarningCard(true);
  };

  const closeCardKidney = () => {
    setShowKidneyCard(false);
  };

  const handleKidneyClick = () => {
    setShowKidneyCard(true);
  };


  // Definerer en liste over medikamentkategorier som kun skal vises for pasienter over 65
  const ageSensitiveSummaries = [
      "<div xmlns=\"http://www.w3.org/1999/xhtml\">TCA (trisykliske antidepressiver)</div>",
      "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antipsykotika (nevroleptika)</div>",
      "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antihistaminer – 1. generasjon</div>",
      "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antikolinerge midler mot overaktiv blære</div>",
      "<div xmlns=\"http://www.w3.org/1999/xhtml\">Sulfonylurea</div>"
    ];


  // Definer tilstandsvariabler og funksjoner her
  const [error, setError] = useState(null); // Tilstand for feilmeldinger

  // Funksjon for å håndtere lukking av feilmodal
  const handleCloseErrorModal = () => setError(null);

  // Funksjon for å håndtere feil
  const handleError = (errorMessage) => setError(errorMessage);

  // Resten av komponentlogikken din...




  const callCDS = (suggestion) => {
    setLoader(true); // Start med å sette loader til true
  
    const createRequestBody = (patientData, selectedMedication, gfrValue) => {
      let requestBody = {
        hook: "patient-view",
        context: {
          userId: `Patient/${patientData.id}`,
          patientId: patientData.id,
        },
        prefetch: {
          patient: {
            resourceType: "Patient",
            id: patientData.id,
            birthDate: patientData.birthDate,
          },
          medications: {
            resourceType: "Bundle",
            type: "searchset",
            total: 1,
            entry: [{
              resource: {
                resourceType: "MedicationRequest",
                status: "active",
                intent: "order",
                medication: {
                  concept: {
                    coding: [{
                      code: selectedMedication.concept.conceptId,
                      system: "http://snomed.info/sct",
                      display: selectedMedication.concept.display,
                    }],
                  },
                },
                subject: {
                  reference: `Patient/${patientData.id}`,
                },
              },
            }],
          },
        },
      };
  
      if (gfrValue !== -1) {
        requestBody.prefetch.observations = {
          resourceType: "Bundle",
          type: "searchset",
          total: 1,
          entry: [{
            resource: {
              resourceType: "Observation",
              id: "value-in-range-observation-gfr",
              status: "final",
              code: {
                coding: [{
                  system: "http://snomed.info/sct",
                  code: "80274001",
                  display: "Glomerular filtration rate",
                }],
              },
              subject: {
                reference: `Patient/${patientData.id}`,
              },
              valueQuantity: {
                value: `${gfrValue}`,
                unit: "ml/min",
                system: "http://unitsofmeasure.org",
                code: "mL/min",
              },
            },
          }],
        };
      }
  
      return requestBody;
    };
  
    if (params) {
      Promise.all([
        fetch(fhirServer + `/Patient?_id=${params.patientId}`),
        fetch(fhirServer + `/Patient/${params.patientId}/$everything?_count=2000`),
        fetch(fhirServer + `/MedicationRequest?patient=${params.patientId}&_count=2000`),
      ])
        .then((responses) => Promise.all(responses.map((response) => {
          if (!response.ok) {
            throw new Error(`Nettverksforespørsel til ${response.url} feilet med status ${response.status}`);
          }
          return response.json();
        })))
        .then(([patientData, everythingData, medicationData]) => {
          if (!patientData || !patientData.entry || patientData.entry.length === 0) {
            console.error("Ingen pasientdata funnet");
            return;
          }
          let patientDataToUse = patientData.entry[0].resource;
  
          let gfrObservations = everythingData?.entry
            .filter(entry => entry.resource.resourceType === "Observation")
            .map(obs => obs.resource)
            .filter(obs => obs.code.coding.some(coding => coding.system === "http://snomed.info/sct" && coding.code === "80274001"));
  
          gfrObservations.sort((a, b) => new Date(b.effectiveDateTime) - new Date(a.effectiveDateTime));
          let newestGfrObservation = gfrObservations[0];
  
          let gfrValue = newestGfrObservation ? newestGfrObservation.valueQuantity.value : -1;
  
          console.log('GFR Value to Use', gfrValue);
  
          const requestBody = createRequestBody(patientDataToUse, suggestion, gfrValue);
  
          let ageYears = 0;
          const birthDateString = patientDataToUse.birthDate;
          if (birthDateString) {
            const birthDate = new Date(birthDateString);
            const ageMs = Date.now() - birthDate.getTime();
            const ageExactYears = ageMs / (1000 * 3600 * 24 * 365.25);
            ageYears = Math.floor(ageExactYears);
          }
          setPatientAge(ageYears);
  
          fetch(r5FhirServerCdsServices, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`Network response was not ok from ${response.url}`);
            }
            return response.json();
          })
          .then(resultFromFirstService => {
            const shouldShowWarning = resultFromFirstService.cards && resultFromFirstService.cards.some(card => {
              return ageYears > 65 || !ageSensitiveSummaries.some(summary => card.summary.includes(summary));
            });
  
            setCdsResponseData({
              ...resultFromFirstService,
              showWarningTriangle: shouldShowWarning
            });
            setShouldShowWarningTriangle(shouldShowWarning);
          })
          .catch((error) => {
            console.error("Det oppstod en feil under innsending av forespørsel til første service:", error);
          });
  
          if (gfrValue !== -1) {
            fetch(r5FhirServerCdsServicesNyre, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            })
            .then(response => {
              if (!response.ok) {
                throw new Error(`Network response was not ok from ${response.url}`);
              }
              return response.json();
            })
            .then(resultFromSecondService => {
              const shouldShowKidney = resultFromSecondService.cards && resultFromSecondService.cards.some(card => {
                return ageYears > 65 || !ageSensitiveSummaries.some(summary => card.summary.includes(summary));
              });
              setKidneyResponseData({
                ...resultFromSecondService,
                showKidneySymbol: shouldShowKidney
              });
              setShouldShowKidneySymbol(shouldShowKidney);
            })
            .catch((error) => {
              console.error("Det oppstod en feil under innsending av forespørsel til andre service:", error);
            });
          } else {
            // Håndter tilfellet der GFR ikke finnes
            setKidneyResponseData(null);
            setShouldShowKidneySymbol(false);
          }
        })
        .catch((error) => {
          console.error("Det oppstod en feil under innhenting av data:", error);
          setLoader(false);
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  };






  const inputProps = {
    placeholder: "Skriv minst tre bokstaver for forslag...",
    required: true,
    value,
    onChange: (_e, { newValue }) => setValue(newValue),
  }

  const inputPropsCondition = {
    placeholder: "Skriv minst tre bokstaver for forslag...",
    value: ConditionValue,
    // required: true,
    required: false,
    onChange: (_e, { newValue }) => setConditionValue(newValue),
  }

  const getSuggestionValue = (suggestion) => {
    setSelectedMediccation(suggestion)
    callCDS(suggestion)
    return (
      suggestion.concept.pt.term +
      " (SCTID: " +
      suggestion.concept.conceptId +
      ")"
    )
  }

  const getSuggestionValueCondition = (suggestion) => {
    setSelectedCondition(suggestion)
    return (
      suggestion.concept.pt.term +
      " (SCTID: " +
      suggestion.concept.conceptId +
      ")"
    )
  }

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.concept.pt.term +
        " (SCTID: " +
        suggestion.concept.conceptId +
        ")"}
    </div>
  )

  const renderSuggestionCondition = (suggestion) => (
    <div>
      {/** taking from hdir descriptions */}
      {suggestion.concept.pt.term +
        " (SCTID: " +
        suggestion.concept.conceptId +
        ")"}
    </div>
  )

  const filterSuggestions = async ({ value }) => {
    if (value.length < 3) return;
    try {
      const url = conceptQueryClincialDrug + value;
      const res = await fetch(url, { headers: snowstormHeaders });
      if (!res.ok) throw new Error(`Feil ved henting av data fra ${url}: ${res.status}`);
      const results = await res.json();
      setSuggestions(results.items);
    } catch (error) {
      handleError(error.message);
      console.error("En feil oppstod under filtrering av forslag:", error);
      // Her kan du velge å vise en feilmelding i UI eller håndtere feilen på en annen måte.
    }
  };

  const filterSuggestionsCondition = async ({ value }) => {
    if (value.length < 3) return;
    try {
      const url = conceptQueryfindingAndDisorder + value;
      const res = await fetch(url, { headers: snowstormHeaders });
      if (!res.ok) throw new Error(`Feil ved henting av data fra ${url}: ${res.status}`);
      const results = await res.json();
      setConditionSuggestions(results.items);
    } catch (error) {
      handleError(error.message);
      console.error("En feil oppstod under filtrering av forslag for tilstand:", error);
      // Samme feilhåndtering som over.
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData(formRef.current)
    // const category = requestCategories.find(
    //   (cat) => cat.code === event.target.elements.category.value
    // );
    const courseType = courseTypes.find(
      (type) => type.code === formData.get("courseType")
    )

    const MedicationRequestData = {
      resourceType: "MedicationRequest",
      status: "active",
      intent: "order",
      dispenseRequest: {
        validityPeriod: {
          start: new Date(),
          end: formData.get("validityPeriod")
            ? new Date(formData.get("validityPeriod"))
            : undefined,
        },
      },
      subject: {
        reference: `Patient/${formData.get("patientId")}`,
      },
      priority: "routine",
      medicationCodeableConcept: {
        coding: [
          {
            code: selectedMedication.concept.conceptId,
            system: "http://snomed.info/sct",
            display: selectedMedication.concept.pt.term,
          },
        ],
      },
    }

    // filling in "courseOfTherapyType"
    if (courseType) {
      MedicationRequestData.courseOfTherapyType = { coding: [courseType] }
    }

    // filling in "reasonReference"
    if (
      selectedCondition &&
      selectedCondition.concept.conceptId &&
      selectedCondition.concept.pt.term
    ) {
      MedicationRequestData.reasonReference = [
        {
          reference: `Condition/${selectedCondition.concept.conceptId}`,
          display: selectedCondition.concept.pt.term,
        },
      ]
    }

    // filling in dosageInstruction
    if (formData.get("dosageInstruction") !== "") {
      MedicationRequestData.dosageInstruction =
        MedicationRequestData.dosageInstruction || []
      MedicationRequestData.dosageInstruction[0] =
        MedicationRequestData.dosageInstruction[0] || {}
      MedicationRequestData.dosageInstruction[0].text =
        formData.get("dosageInstruction")
    }

    // filling in dispenseRequest.numberOfRepeatsAllowed
    if (formData.get("numberOfRepeatsAllowed") !== "") {
      MedicationRequestData.dispenseRequest.numberOfRepeatsAllowed = parseInt(
        formData.get("numberOfRepeatsAllowed")
      )
    }

    // filling in dispenseRequest.quantity
    if (formData.get("quantity") !== "") {
      MedicationRequestData.dispenseRequest.quantity =
        MedicationRequestData.dispenseRequest.quantity || {}
      MedicationRequestData.dispenseRequest.quantity.value = parseInt(
        formData.get("quantity")
      )
    }

    // filling in note
    if (formData.get("note")) {
      MedicationRequestData.note = [
        {
          text: formData.get("note"),
          time: new Date(),
        },
      ]
    }

    const res = await fetch(fhirServer + "/MedicationRequest", {
      method: "POST",
      body: JSON.stringify(MedicationRequestData),
      headers: headers,
    })

    await res.json()
    setFormDetails(MedicationRequestData)
  }

  const handleButtonClick = async (event) => {
    if (dataCards?.length > 0) {
      // confirmation
      setIsModalOpen(true)
    } else {
      // no cards, prescription is allowed:
      await handleSubmit(event)
    }
  }

  const formRef = useRef(null)

  return (
    <Container>
      <Button className="home" onClick={() => navigator(`/`)}>
        <HomeIcon />
      </Button>
      <br />
      <Button
        className="back submit-button"
        onClick={() => {
          navigator(`${location.state.path}/${location.state.id ?? ""}`)
          // navigator(-1);
          // forceUpdate(true);
        }}
      >
        <ArrowBackIcon />
      </Button>

      {/* <Container> */}
      <Row>
        <Col>
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              handleButtonClick(event)
            }}
            className="form1"
            ref={formRef}
          >
            <h3 className="text-left">Forskrivning</h3>
            <Container className="c5" xs={40}>
              <Row xs={40}>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Pasient-id FHIR</Form.Label>
                      <Form.Control
                        required
                        name="patientId"
                        placeholder="Pasient Id"
                        value={params.patientId}
                        onChange={() => {}}
                        readOnly
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>

                <Col>
                <FloatingLabel className="my-3 position-relative"> {/* Legg til position-relative her */}
                 <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>Ordinasjon</Form.Label>
                    <Autosuggest
                      className="react-autosuggest__input"
                      required
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={filterSuggestions}
                      onSuggestionsClearRequested={() => setSuggestions([])}
                      onSuggestionSelected={(_, { suggestionValue }) =>
                        console.log("Selected: " + suggestionValue)
                      }
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                      value={value}
                    />
                    <div className="icons-container">
                      {shouldShowKidneySymbol && (
                        <Button
                          variant="info"
                          size="sm"
                          className="text-kidney1"
                          onClick={handleKidneyClick} // Sørger for at denne funksjonen også håndterer nyreknappens logikk
                        >
                          <img
                            src={kidneySymbolImage}
                            alt="Nyresymbol"
                            className="text-kidney2"
                          />
                        </Button>
                      )}        
                      {shouldShowWarningTriangle && (
                        <Button
                          variant="info"
                          size="sm"
                          className="text-warning1b"
                          onClick={handleTriangleClick} // Denne må kanskje differensieres basert på hvilken knapp som klikkes
                        >
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </Button>
                      )}
                    </div>                    
                  </Form.Group>
                </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Bruk</Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        style={{ height: "40px" }}
                        name="courseType"
                        onChange={() => {}}
                        // required
                      >
                        <option value="">Velg </option>
                        {courseTypes.map((type) => {
                          return (
                            <option key={type.code} value={type.code}>
                              {type.display}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Indikasjon (valgfritt)</Form.Label>
                      <Autosuggest
                        suggestions={ConditionSuggestions}
                        onSuggestionsFetchRequested={filterSuggestionsCondition}
                        onSuggestionsClearRequested={() =>
                          setConditionSuggestions([])
                        }
                        onSuggestionSelected={(_, { setConditionValue }) =>
                          console.log("Selected: " + setConditionValue)
                        }
                        getSuggestionValue={getSuggestionValueCondition}
                        renderSuggestion={renderSuggestionCondition}
                        inputProps={inputPropsCondition}
                        value={ConditionValue}
                      />
                    </Form.Group>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row xs={40}>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Dosering</Form.Label>
                      <Form.Control
                        required
                        name="dosageInstruction"
                        type="text"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row xs={40}>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Reit</Form.Label>
                      <Form.Select required name="numberOfRepeatsAllowed">
                        <option value={""}>Velg</option>
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                      </Form.Select>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Antall</Form.Label>
                      <Form.Select required name="quantity">
                        <option value={""}>Velg</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </Form.Select>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Utløpsdato</Form.Label>
                      <Form.Control
                        required
                        name="validityPeriod"
                        type="date"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row xs={40}>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Notat (valgfritt)</Form.Label>
                      <Form.Control
                        name="note"
                        placeholder="Notat"
                        as="textarea"
                      />
                    </Form.Group>
                  </FloatingLabel>
                </Col>
              </Row>
            </Container>

            <Button type="submit" className="submit-button">
              Registrer resept
            </Button>
            <Modal
              isOpen={isModalOpen}
              closeModal={() => setIsModalOpen(false)}
              onYes={async () => {
                console.log("Yes clicked")
                setIsModalOpen(false)
                await handleSubmit()
              }}
              onNo={() => {
                console.log("No clicked")
                setIsModalOpen(false)
              }}
            >
              <p>
                Er du sikker på at du vil foreskrive dette legemidlet selv om
                det har advarsler?
              </p>
            </Modal>

            {loader && <Loader />}

            <br />
            {!!formDetails && (
              <Container>
                <FloatingLabel className="col4">
                  <Button
                    className="showFhir"
                    variant="outline-info"
                    onClick={() => setShowJson(!showJson)}
                  >
                    {showJson ? "Skjul FHIR-data" : "Vis FHIR-data"}
                  </Button>
                  {showJson && (
                    // <div>
                    <Container>
                      <FloatingLabel controlId="floatingTextarea2">
                        {/* <br /> */}
                        <h2>FHIR-data</h2>
                        <Form.Control
                          value={JSON.stringify(formDetails, null, 2)}
                          as="textarea"
                          placeholder="For example : "
                          style={{ height: "430px" }}
                          onChange={() => {}}
                        />
                      </FloatingLabel>
                    </Container>
                  )}
                </FloatingLabel>
              </Container>
            )}
          </Form>
        </Col>
        <Col>
          {selectedMedication && (
            <Button
              className="showFKText"
              onClick={() => setIsIFrameOpen(!isIFrameOpen)}
            >
              {isIFrameOpen ? "Skjule" : "Vis"}
            </Button>
          )}
          {isIFrameOpen && selectedMedication && (
            <Container className="iiframe">
              <iframe
                src={
                  "https://www.felleskatalogen.no/ir/medisin/sok?type=fktekst-liste&felt=sctid,festid&utvidet=ja&sokord=" +
                  selectedMedication.concept.conceptId
                }
                name="Felleskatalogen"
                scrolling="yes"
                frameBorder="2"
                marginHeight="10px"
                marginWidth="10px"
                height="1000px"
                width="100%"
                allowFullScreen
                title="felleskatalogen"
                fontSize="1.9em"
              />
            </Container>
          )}
        </Col>
      </Row>

      <ClinicalUseDefinitionComponent
        clinicalUseDefinitionResources={clinicalUseDefinitionResources}
        closeCard={() => closeCard()}
      />

{/*
showCard && (
<Modal show={showCard} onHide={closeCard1}>
        <CdsResponseComponent
          cdsResponseData={cdsResponseData}
          closeCard1={closeCard1}
          patientAge={patientAge}
          showCard={showCard}
        />
        </Modal>
        )
*/ }
{
  showWarningCard && (
    <Modal show={showWarningCard} onHide={closeCard1}>
      <CdsResponseComponent
        cdsResponseData={cdsResponseData}  // Dette antas å være data for advarselstrekantkortet
        closeCard1={closeCard1}
        patientAge={patientAge}
        showWarningCard={showWarningCard}
      />
    </Modal>
  )
}

{
  showKidneyCard && (
    <Modal show={showKidneyCard} onHide={closeCardKidney}>
      <CdsKidneyComponent // Anta at dette er en ny komponent for nyre-kortet
        kidneyResponseData={kidneyResponseData}  // Bruker kidneyResponseData her
        closeCardKidney={closeCardKidney}
        patientAge={patientAge}
        showKidneyCard={showKidneyCard}
      />
    </Modal>
  )
}

               
<div>
      {/* Din komponent JSX her */}
      {error && <ErrorModal errorMessage={error} onClose={handleCloseErrorModal} />}
      {/* Andre komponenter og JSX... */}
    </div>

    </Container>


    // </Container>
  )
}

export default MedicationRequest



