import React, { useState, useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import "react-phone-input-2/lib/style.css"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import "./AutoComplete.css"
import Autosuggest from "react-autosuggest"
import Card from "react-bootstrap/Card"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import HomeIcon from "@mui/icons-material/Home"
import { indikasjonUrl } from "../config.js"
import {
  conceptQueryBodyStructure,
  conceptQueryfindingAndDisorder,
  conteirApiAccessToken,
  conteirApiURL,
  fhirServer,
  headers,
  smartAppUrl,
  snowstormHeaders,
} from "../config.js"
import ErrorModal from './ErrorModal';

//const relations =[{ code: "BP", system: "http://terminology.hl7.org/CodeSystem/v2-0131", display: "Billing contact person", defination: "Billing contact person" }]
const clinicalConditions = [
  { code: "active", display: "Aktiv" },
  { code: "recurrence", display: "Gjentakende" },
  { code: "relapse", display: "Tilbakefall" },
  { code: "inactive", display: "Inaktiv" },
  { code: "resolved", display: "Løst" },
  { code: "remission", display: "Remisjon" },
  { code: "unknown", display: "Ukjent" },
]

const verificationStatus = [
  { code: "unconfirmed", display: "Ubekreftet" },
  { code: "provisional", display: "Foreløpig" },
  { code: "differential", display: "Differensialdiagnose" },
  { code: "confirmed", display: "Bekreftet" },
  { code: "refuted", display: "Avkreftet" },
  //{ code: "entered-in-error", display: "Oppgitt i feil" },
]

function Condition() {
  const [value, setValue] = useState("")
  const [bodySiteValue, setBodySiteValue] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [bodySiteSuggestions, setBodySiteSuggestions] = useState([])
  const [selectedBodySite, setSelectedBodySite] = useState(undefined)
  const [selectedCondition, setSelectedCondition] = useState(undefined)
  const [conditionCards, setConditionCards] = useState([])
  const [submissionTime, setSubmissionTime] = useState(null)
  const [showJson, setShowJson] = useState(false)

  const [formDetails, setFormDetails] = useState(undefined)
  const [showMoreConditionCard, setShowMoreConditionCard] = useState(undefined)
  const params = useParams()
  const location = useLocation()

  const inputProps = {
    placeholder: "Skriv minst tre bokstaver for å søke...",
    value,
    required: true,
    onChange: (_e, { newValue }) => setValue(newValue),
  }

  useEffect(() => {}, [])

  const getSuggestionValue = (suggestion) => {
    setSelectedCondition(suggestion)
    getConditionCard(suggestion.concept.conceptId)
    return (
      suggestion.concept.pt.term +
      " (SCTID: " +
      suggestion.concept.conceptId +
      ")"
    )
  }

  const bodySiteInputProps = {
    placeholder: "Skriv minst tre bokstaver for å søke...",
    value: bodySiteValue,
    onChange: (_e, { newValue }) => setBodySiteValue(newValue),
  }

  const getBodySiteSuggestionValue = (suggestion) => {
    setSelectedBodySite(suggestion)
    return suggestion.pt.term + " (SCTID: " + suggestion.conceptId + ")"
  }

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.concept.pt.term +
        " (SCTID: " +
        suggestion.concept.conceptId +
        ")"}
    </div>
  )

  const renderBodySiteSuggestion = (suggestion) => (
    <div>{suggestion.pt.term + " (SCTID: " + suggestion.conceptId + ")"}</div>
  )

  const filterSuggestions = async ({ value }) => {
    if (value.length < 3) return
    try {
    const url = conceptQueryfindingAndDisorder + value
    const res = await fetch(url, {
      headers: snowstormHeaders,
    })
    if (!res.ok) throw new Error(`Feil ved henting av data fra ${url}: ${res.status}`);
    const results = await res.json()
    setSuggestions(results.items)
  } catch (error) {
    handleError(error.message);
    console.error("En feil oppstod under filtrering av forslag:", error);
    // Her kan du velge å vise en feilmelding i UI eller håndtere feilen på en annen måte.
  }
};

  const filterBodySiteSuggestions = async ({ value }) => {
    if (value.length < 4) return
    try {
    const url = conceptQueryBodyStructure + value
    const res = await fetch(url, {
      headers: snowstormHeaders,
    })
    if (!res.ok) throw new Error(`Feil ved henting av data fra ${url}: ${res.status}`);
    const results = await res.json()
    setBodySiteSuggestions(results.items)
  } catch (error) {
    handleError(error.message);
    console.error("En feil oppstod under filtrering av forslag:", error);
    // Her kan du velge å vise en feilmelding i UI eller håndtere feilen på en annen måte.
  }
};

  const getConditionCard = async (code) => {
    const cardsRes = await fetch(conteirApiURL(code), {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${conteirApiAccessToken}`,
      },
    })

    const cards = await cardsRes.json()
    setConditionCards(cards.cards)
  }

  // Definer tilstandsvariabler og funksjoner her
  const [error, setError] = useState(null); // Tilstand for feilmeldinger

  // Funksjon for å håndtere lukking av feilmodal
  const handleCloseErrorModal = () => setError(null);

  // Funksjon for å håndtere feil
  const handleError = (errorMessage) => setError(errorMessage);
  


  const navigator = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()
    console.log(event.target.elements.patientId.value)
    console.log("date", event.target.elements.date.value)

    let conditionRequestData = {
      resourceType: "Condition",
      subject: {
        reference: `Patient/${event.target.elements.patientId.value}`,
      },
      category: [
        {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-category",
              code: "encounter-diagnosis",
              display: "Besøksdiagnose",
            },
          ],
        },
      ],
      code: {
        coding: [
          {
            code: selectedCondition.concept.conceptId,
            system: "http://snomed.info/sct",
            display: selectedCondition.concept.pt.term,
          },
        ],
      },
      clinicalStatus: {
        coding: [
          {
            code: event.target.elements.clinicalStatus.value,
            system: "http://terminology.hl7.org/CodeSystem/condition-clinical",
            display:
              clinicalConditions.find(
                (s) => s.code === event.target.elements.clinicalStatus.value
              )?.display ?? "",
          },
        ],
      },
      // verificationStatus: [
      //   {
      //     coding: [
      //       {
      //         code: event.target.elements.verificationStatus.value,
      //         system:
      //           "http://terminology.hl7.org/CodeSystem/condition-ver-status",
      //         display:
      //           verificationStatus.find(
      //             (s) =>
      //               s.code === event.target.elements.verificationStatus.value
      //           )?.display ?? "",
      //       },
      //     ],
      //   },
      // ],
      // note: {
      //   time: new Date(),
      //   text: event?.target.elements.note.value,
      // },
      recordedDate: new Date(),
      onsetDateTime: new Date(event.target.elements.date.value),
    }

    if (event.target.elements.verificationStatus.value) {
      conditionRequestData = {
        ...conditionRequestData,
        verificationStatus: {
          coding: [
            {
              code: event.target.elements.verificationStatus.value,
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              display:
                verificationStatus.find(
                  (s) =>
                    s.code === event.target.elements.verificationStatus.value
                )?.display ?? "",
            },
          ],
        },
      }
    }

    // filling in note:
    if (event.target.elements.note.value) {
      conditionRequestData.note = [
        {
          text: event.target.elements.note.value,
        },
      ]
    }

    if (selectedBodySite?.conceptId) {
      conditionRequestData = {
        ...conditionRequestData,
        bodySite: [
          {
            coding: [
              {
                code: selectedBodySite.conceptId,
                system: "http://snomed.info/sct",
                display: selectedBodySite.pt.term,
              },
            ],
          },
        ],
      }
    }

    const res = await fetch(fhirServer + "/Condition", {
      method: "POST",
      body: JSON.stringify(conditionRequestData),
      headers: headers,
    })

    await res.json()
    setFormDetails(conditionRequestData)
    setSubmissionTime(new Date())
  }

  return (
    <Container>
      <Button className="back" onClick={() => navigator(`/`)}>
        <HomeIcon />
      </Button>
      <br />
      <Button
        className="back"
        onClick={() =>
          navigator(`${location.state.path}/${location.state.id ?? ""}`)
        }
      >
        <ArrowBackIcon />
      </Button>
      <h1>Diagnose eller problemstilling</h1>
      <Form onSubmit={handleSubmit}>
        <Container className="c5" xs={40}>
          <Row xs={40}>
            <Col>
              <FloatingLabel className="my-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Pasient-id FHIR</Form.Label>
                  <Form.Control
                    required
                    name="patientId"
                    placeholder="Patient Id"
                    value={params.patientId}
                    onChange={() => {}}
                    readOnly
                    disabled
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </FloatingLabel>
            </Col>

            <Col>
              <FloatingLabel className="my-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Diagnose/problemstilling</Form.Label>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={filterSuggestions}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    onSuggestionSelected={(_, { suggestionValue }) =>
                      console.log("Selected: " + suggestionValue)
                    }
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    value={value}
                  />
                </Form.Group>
              </FloatingLabel>
            </Col>
          </Row>

          <Row xs={40}>
            <Col>
              <FloatingLabel className="my-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Klinisk status</Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    name="clinicalStatus"
                    onChange={() => {}}
                    required
                  >
                    {clinicalConditions.map((conditioon, idx) => {
                      return (
                        <option key={conditioon.code} value={conditioon.code}>
                          {conditioon.display}
                        </option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </FloatingLabel>
            </Col>

            <Col>
              <FloatingLabel className="my-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Bekreftelsesstatus (valgfritt)</Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    name="verificationStatus"
                    onChange={() => {}}
                  >
                    <option value="">Velg status</option>
                    {verificationStatus.map((status) => {
                      return (
                        <option key={status.code} value={status.code}>
                          {status.display}
                        </option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </FloatingLabel>
            </Col>
          </Row>

          <Row xs={40}>
            <Col>
              <FloatingLabel className="my-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Kroppssted (valgfritt)</Form.Label>
                  <Autosuggest
                    suggestions={bodySiteSuggestions}
                    onSuggestionsFetchRequested={filterBodySiteSuggestions}
                    onSuggestionsClearRequested={() =>
                      setBodySiteSuggestions([])
                    }
                    onSuggestionSelected={(_, { suggestionValue }) =>
                      console.log("Selected: " + suggestionValue)
                    }
                    getSuggestionValue={getBodySiteSuggestionValue}
                    renderSuggestion={renderBodySiteSuggestion}
                    inputProps={bodySiteInputProps}
                    value={bodySiteValue}
                  />
                </Form.Group>
              </FloatingLabel>
            </Col>
          </Row>

          <Row xs={40}>
            <Col>
              <FloatingLabel className="my-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Notat (valgfritt)</Form.Label>
                  <Form.Control
                    name="note"
                    placeholder="Fritekst ved behov"
                    as="textarea"
                  />
                </Form.Group>
              </FloatingLabel>
            </Col>
          </Row>

          <Row xs={40}>
            <Col>
              <FloatingLabel className="my-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    <h5 className="text-left">Diagnosedato</h5>
                  </Form.Label>
                  <Form.Control type="date" name="date" required></Form.Control>
                </Form.Group>
              </FloatingLabel>
            </Col>
          </Row>
        </Container>
        <Container>
          <Button type="submit" className="submit-button">
            Registrer
          </Button>
        </Container>
        <br />
        <br />
        <Container className="hidden">
          <Row className="date">
            {!!submissionTime && (
              <Form.Label>
                {" "}
                <h5 className="date">Registrert:</h5>{" "}
                {submissionTime.toLocaleString("nb-NB")}
              </Form.Label>
            )}
          </Row>
        </Container>
        <br />
        {!!formDetails && (
          <Container>
            <FloatingLabel className="col4">
              <Button
                className="showFhir"
                variant="outline-info"
                onClick={() => setShowJson(!showJson)}
              >
                {showJson ? "Skjul  FHIR-data" : "Vis FHIR-data"}
              </Button>
              {showJson && (
                // <div>
                <Container>
                  <FloatingLabel controlId="floatingTextarea2">
                    {/* <br /> */}
                    <h2>FHIR-data</h2>
                    <Form.Control
                      value={JSON.stringify(formDetails, null, 2)}
                      as="textarea"
                      placeholder="For example : "
                      style={{ height: "430px" }}
                      onChange={() => {}}
                    />
                  </FloatingLabel>
                </Container>
              )}
            </FloatingLabel>
          </Container>
        )}
        {!!selectedCondition && (
          <Container>
            {" "}
            <Button
              className="secondaryCta"
              onClick={() => {
                window.open(smartAppUrl(selectedCondition.concept?.conceptId))
              }}
            >
              Smartapp beslutningsstøtte
            </Button>{" "}
            <Button
              className="secondaryCta"
              onClick={() =>
                window.open(
                  `${indikasjonUrl}` + selectedCondition?.concept?.conceptId,
                  "_blank"
                )
              }
            >
              Legemidler ved{" "}
              <strong> {selectedCondition?.concept?.pt?.term}</strong>
            </Button>
          </Container>
        )}
        <br />
        <Container>
          {conditionCards.map((card, idx) => {
            return (
              <Container key={`condition-card-${idx}`}>
                <FloatingLabel>
                  <Card className="pulsecard">
                    <Card.Body>
                      <h3>{card.summary}</h3>
                      <b>Kilde</b>:{" "}
                      <span
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        {card.source.join(",")}
                      </span>
                      {card.detail && (
                        <>
                          {showMoreConditionCard === `condition-card-${idx}` ? (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${card.detail}` ?? "",
                                }}
                              ></div>
                              <Button
                                onClick={() =>
                                  setShowMoreConditionCard(undefined)
                                }
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  background: "#619ca5",
                                  border: "#619ca5",
                                }}
                              >
                                Vis mindre
                              </Button>
                            </>
                          ) : (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    `${card.detail?.substring(0, 150)}...` ??
                                    "",
                                }}
                              ></div>
                              <Button
                                onClick={() =>
                                  setShowMoreConditionCard(
                                    `condition-card-${idx}`
                                  )
                                }
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  background: "#619ca5",
                                  border: "#619ca5",
                                }}
                              >
                                Vis mer
                              </Button>
                            </>
                          )}
                        </>
                      )}
                      <br />
                      {card?.links?.length > 0 && (
                        <>
                          {card?.links.map((link, index) => {
                            return (
                              <a
                                key={index}
                                href={link.url}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {link.label}
                                <br />
                              </a>
                            )
                          })}
                        </>
                      )}
                      <hr />
                    </Card.Body>
                  </Card>
                </FloatingLabel>
              </Container>
            )
          })}
        </Container>
      </Form>{" "}

      <div>
      {/* Din komponent JSX her */}
      {error && <ErrorModal errorMessage={error} onClose={handleCloseErrorModal} />}
      {/* Andre komponenter og JSX... */}
    </div>
    </Container>
  )
}

export default Condition
