import Cookies from "js-cookie"
import {
  Container,
  Row,
  Col,
  FloatingLabel,
  Button,
  Card,
  Form,
} from "react-bootstrap"
// import { conteirApiAccessToken, conteirApiURL } from "../../config.js";
import React, {
  useState,
  useRef,
  useEffect,
  useContext /*, useNavigate */,
} from "react"
import AuthContext from "../../context/AuthProvider"
// import Home from "../../Home";
import { Navigate } from "react-router-dom"
import { authServer } from "../../config.js"

// import axios from "../api/axios";
// const LOGIN_URL = "/login";

// import PropTypes from "prop-types";
// import "./Login.css";

// async function loginUser(credentials) {
//   // return fetch('https://smartauth.herokuapp.com/login', {
//   return fetch("http://localhost:8080/login", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(credentials),
//   }).then((data) => data.json());
// }

// const getCards = async () => {
//     const urlParams = new URL(document.location).searchParams;

//     fetch(conteirApiURL(urlParams.get("code")), {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         Authorization: `Bearer ${conteirApiAccessToken}`,
//       },
//     })
//     .then(res => res.json)
//     .then(cards => console.log("cards", cards));

//     // const cards = await cardsRes.json();
// };

// const logout = async () => {

//   // const urlParams = new URL(document.location).searchParams;
//   fetch("http://localhost:8080/logout", {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       // Authorization: `Bearer ${conteirApiAccessToken}`,
//     },
//   })
//   // .then(res => res.json)
//   .then(resp => {
//     console.log("response status", resp.status);
//     // if (resp.status===204) setSuccess(false);
//   });
// };

// export default function Login({ setToken }) {
const LoginComponent = () => {
  // const navigator = useNavigate();

  const { auth, setAuth } = useContext(AuthContext)
  console.log("auth", auth)
  const userRef = useRef()
  const errRef = useRef()

  const [success, setSuccess] = useState(false)
  const [user, setUserName] = useState("")
  const [pwd, setPassword] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    fetch(authServer + "/isLoggedIn", {
      credentials: "include",
    })
      .then((data) => data.json())
      .then((resp) => {
        console.log(resp)
        if (resp.authorized) setSuccess(true)
      })
  }, [])

  useEffect(() => {
    userRef.current.focus()
  }, [])

  useEffect(() => {
    setError("")
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      fetch(authServer + "/login", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user, pwd }),
      })
        .then((data) => data.json())
        .then((data) => {
          console.log("data", data)

          //   const response = await axios.post(
          //     LOGIN_URL,
          //     JSON.stringify({ user: user, pwd: pwd }),
          //     {
          //       headers: {
          //         "Content-Type": "application/json",
          //         "Access-Control-Allow-Credentials": "true"
          //       },
          //       withCredentials: true,
          //     }
          //   );
          //   console.log(JSON.stringify(response));

          const accessToken = data.accessToken
          const refreshToken = data.refreshToken

          const gottenCookie = Cookies.get("_session.legacy")
          console.log("gottenCookie", gottenCookie)
          let cookieValue = document.cookie.replace(
            /(?:(?:^|.*;\s*)username\s*\s*([^;]*).*$)|^.*$/,
            "$1"
          )
          console.log("cookieValue", cookieValue)

          setAuth({
            user: user,
            pwd: pwd,
            accessToken: accessToken,
            refreshToken: refreshToken,
          })
          setUserName("")
          setPassword("")
          setSuccess(true)
        })
    } catch (err) {
      console.log("err", err)
      if (!err?.response) {
        setError("No server response")
      } else if (err.response?.status === 400) {
        setError("Missing username/password")
      } else if (err.response?.status === 401) {
        setError("Unauthorized")
      } else {
        setError("Login failed")
      }
      errRef.current.focus()
    }

    // const res = await loginUser({
    //   user: username,
    //   pwd: password,
    // });
    // if (res.refreshToken) setToken(res.refreshToken);
    // else setError("invalid");
  }

  return (
    <>
      {success ? (
        // <section>
        /* <div className="row">
            <div className="col-md-6">
              <h1>You are logged inn i FHIR-Simulator</h1>
              <a href="#" onClick={getCards}>Get cards</a>
            </div>
          </div> */
        <Navigate to="/" />
      ) : (
        // <Home />
        // </section>
        <Container>
          <br />
          {/* <section>
            <p
              ref={errRef}
              className={error ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {error}
            </p>
            <h1>Sign In</h1>
          </section>*/}
          <br />
          <Row className="justify-content-md-center">
            <Col md="auto">
              {" "}
              <FloatingLabel>
                <Form onSubmit={handleSubmit}>
                  <Card className="card1 p-2" style={{ width: "30rem" }}>
                    <h2>Logg inn FHIR EPJ-simulator</h2>
                    <Row>
                      <FloatingLabel>
                        <Form.Group as={Col}>
                          <Form.Label>Brukernavn</Form.Label>
                          <Form.Control
                            required
                            id="username"
                            name="username"
                            type="text"
                            placeholder="Fornavn"
                            ref={userRef}
                            onChange={(e) => setUserName(e.target.value)}
                            value={user}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </FloatingLabel>
                    </Row>
                    <br />
                    <Row>
                      <FloatingLabel>
                        <Form.Group as={Col}>
                          <Form.Label>Passord</Form.Label>
                          <Form.Control
                            required
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={pwd}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </FloatingLabel>
                    </Row>
                    <br />
                    <fieldset>
                      <br />
                    </fieldset>
                    <Row>
                      <Form.Group controlId="formHorizontalCheck">
                        <Col>
                          <Form.Check label="Husk meg" />
                        </Col>
                      </Form.Group>

                      <br />
                      <Form.Group>
                        <br />
                        <Col>
                          <Button type="submit">Logg inn</Button>
                        </Col>{" "}
                        {error && (
                          <p style={{ color: "red" }}>Ugyldig bruker</p>
                        )}
                      </Form.Group>
                    </Row>
                  </Card>
                </Form>{" "}
              </FloatingLabel>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default LoginComponent
// Login.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };
