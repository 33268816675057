import React, { useContext } from "react"
import AuthContext from "../../context/AuthProvider"
import { authServer } from "../../config.js"
import Button from "react-bootstrap/Button"

// export default function Login({ setToken }) {
const Logout = () => {
  const { auth, setAuth } = useContext(AuthContext)
  console.log("auth", auth)

  const handleSubmit = async (e) => {
    e.preventDefault()
    fetch(authServer + "/logout", {
      credentials: "include",
    }).then((data) => {
      console.log("response status", data.status)
      setAuth({ user: "", pwd: "", accessToken: "", refreshToken: "" })
      window.location.reload()
    })
  }

  return (
    <Button
      variant="outline-secondary"
      onClick={handleSubmit}
      className="floatRight"
    >
      Logg ut
    </Button>
  )
}

export default Logout
