import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { authServer } from "../config.js";

const Root = () => {
  // const navigator = useNavigate();

  const [state, setState] = useState({loaded: false, success: false});

  // Always check if logged in
  useEffect(() => {
    fetch(authServer + "/isLoggedIn", {
      credentials: "include"
    })
    .then((data) => data.json())
    .then((resp) => {
      console.log(resp);
      if (resp.authorized) setState({loaded: true, success: true});
      else setState({loaded: true, success: false});
    });
  }, []);

  if(state.loaded) {
    if (!state.success) {
      return <Navigate to="/login"/>
    } else {
      return <Outlet></Outlet>
    }
  }
  return <></>; // TODO Loading
};

export default Root;